<template>
	<div class="my-4">
		<h1 class="mb-3 font-weight-bold">Kapcsolat</h1>
		<h2>Óbudai Egyetem, Szolgáltatási Igazgatóság</h2>
		<ul class="my-4 h5">
			<li class="my-4">1034 Budapest, Bécsi út 104-108.</li>
			<li class="my-4">E-mail cím: hszo@uni-obuda.hu</li>
			<li class="my-4">Tel: +36 (1) 666-5613</li>
			<li class="my-4">Facebook: https://www.facebook.com/hallgatoikozpontokoe/ </li>
			<li class="my-4">Instagram: https://www.instagram.com/oe_hallgatoikozossegikozpontok/</li>
		</ul>
	</div>
</template>
<script></script>
